<template>
	<div class="wrap">
		<myHead></myHead>
		<div class="wrap_body" v-loading="is_loading">
			<div class="center">
				<div class="cart_main">
					<p class="title_txt">选取列表 ( {{cartList.total}} )</p>
					<div class="list_title">
						<div class="checkall"><el-checkbox :indeterminate="all_indeterminate" v-model="all_select" @change="bindSelectOpt(all_select,'','','','','all')">全选</el-checkbox></div>
						<p class="t1">货品</p>
						<p class="t2">采购价 ( 元 )</p>
					</div>
					<ul class="cart_list" v-if="cartList.total>0">
						<li v-for="(item,index) in cartList.data" :key="index">
							<div class="invalid_box" v-if="item.product.del_date>0">该商品已失效！</div>
							<div class="check_box"><el-checkbox :indeterminate="item.is_indeterminate" :key="item.product_id" v-model="item.is_select==1" @change="bindSelectOpt(item.is_select,item.business_cart_id,'',index,'','item')"></el-checkbox></div>
							<div class="goods_wrap">
								<div class="img_box"><img class="coverimg" :src="item.product.img" /></div>
								<div class="con_box">
									<p class="name">{{item.product.name}}</p>
									<ul class="config_list" v-if="item.sku.length>0">
										<li v-for="(sku,index2) in item.sku" :key="index2">
											<el-checkbox :key="sku.product_sku_id" v-model="sku.pivot.is_select==1" @change="bindSelectOpt(sku.pivot.is_select,item.business_cart_id,sku.product_sku_id,index,index2,'sku')"></el-checkbox>
											<div class="icon_box">
												<img class="coverimg" :src="sku.pic" />
											</div>
											<div class="config">{{sku.spec_sku_name}}</div>
											<i class="iconfont icon-guanbi" @click="bindDeleteProduct('sku',index,index2)"></i>
											<p class="price">￥{{sku.price}}</p>
										</li>
									</ul>
									<div v-if="item.sku.length>0"><p class="more_btn" @click="bindMoreSkus(item.business_cart_id)">再选一款</p></div>
								</div>
							</div>
							<div class="opts_wrap">
								<!-- <p class="opt">生成运费模板</p>
								<p class="opt" @click.self="is_baoyou = true">使用包邮模板</p> -->
								<p class="opt" @click="$router.push('./notice?type=1&id=10')">运费说明</p>
								<i class="iconfont icon-wenhao" @click="$router.push('./notice?type=1&id=10')"></i>
							</div>
							<div class="delete" @click="bindDeleteProduct('item',index)"><i class="iconfont icon-delete"></i></div>
						</li>
					</ul>
					<el-empty v-else description="暂无数据"></el-empty>
					<div class="bottom_total">
						<div class="left">
							<div class="checkall"><el-checkbox :indeterminate="all_indeterminate" v-model="all_select" @change="bindSelectOpt(all_select,'','','','','all')">全选</el-checkbox></div>
							<p class="delete" @click="bindDeleteProduct('all')">删除</p>
						</div>
						<div class="right">
							<p class="total_txt">货品种类 {{select_types}} 种</p>
							<p class="total_txt">数量总计 {{select_nums}} 件</p>
							<p class="submit_btn" @click="callPh()">立即铺货</p>
						</div>
					</div>
				</div>
				<!-- <div :class="is_baoyou?'baoyou_mask active' : 'baoyou_mask'" @click.self="is_baoyou = false">
					<div class="baoyou_main">
						<i class="iconfont icon-guanbi" @click.self="is_baoyou = false"></i>
						<p class="title">包邮模板</p>
						<div class="tx_count">
							<input class="in" placeholder="输入金额" />
						</div>
						<div class="btn_wrap">
							<p class="btn">取消</p>
							<p class="btn">确定</p>
						</div>
					</div>
				</div> -->
				<div class="other_mask" :class="is_other?'active' : ''" @click.self="bindOtherClose('close')">
					<div class="other_main">
						<i class="iconfont icon-guanbi" @click.self="bindOtherClose('close')"></i>
						<div v-if="otherList.length>0" class="main">
							<p class="title">再选一款</p>
							<ul class="config_list">
								<li v-for="(sku,index) in otherList" :key="index">
									<el-checkbox :key="sku.product_sku_id" v-model="sku.is_select==1" @change="bindMoreSelect('sku',index)"></el-checkbox>
									<div class="icon_box">
										<img class="coverimg" :src="sku.pic" />
									</div>
									<div class="config">{{sku.spec_sku_name}}</div>
									<p class="price">￥{{sku.price}}</p>
								</li>
							</ul>
							<div class="bottom_total">
								<div class="checkall"><el-checkbox :indeterminate="other_indeterminate" v-model="other_select" @change="bindMoreSelect('all')">全选</el-checkbox></div>
								<p class="btn" @click.self="bindOtherClose('submit')">确定</p>
							</div>
							
						</div>
						<div class="nodata" v-else>
							<p class="txt">没有更多选择啦~</p>
							<p class="btn" @click.self="bindOtherClose('close')">关闭</p>
						</div>
					</div>
				</div>
				<shopList ref="child"></shopList>
			</div>
		</div>
		<myFoot></myFoot>
	</div>
</template>

<script>
import Vue from 'vue';
import myHead from '../../components/myHead/myhead';
import myFoot from '../../components/myFoot/myfoot';
import shopList from '../../components/shopList/shopList';
import { mapActions,mapState,mapMutations } from "vuex";
export default {
	name: "cart",
	data() {
		return {
			is_baoyou:false,
			is_other:false,
			is_loading: false,
			cartListData:{},
			cartList:{},
			all_indeterminate:false,
			all_select:false,
			select_types:0,
			select_nums:0,
			otherData:{},
			otherList:[],
			other_indeterminate:false,
			other_select:false,
			more_business_cart_id:0,
			is_ph:false,
			ddShopList:[],
			product_id:0,
			checkAll: false,
			checkList: [],
			isLoading:false,
			
			
		}
	},
	components:{
		myHead,myFoot,shopList
	},
	created(){
		this.getList()
	},
	methods: {
		...mapActions({
			getCartList:"cart/getCartList",
			doSelectOptions:"cart/doSelectOptions",
			doDeleteOptions:"cart/doDeleteOptions",
			getMoreSkus:"cart/getMoreSkus",
			doSubmitPH:"cart/doSubmitPH",
			doProductSelect:"index/doProductSelect",
			getDdShopList:"ucenter/getDdShopList",
		}),
		getList(){//获取进货车商品列表
			this.is_loading = true
			this.getCartList({data:{},success:(res)=>{
				if(res.code===200){
					console.log(JSON.parse(JSON.stringify(res)))
					this.cartListData = res.data
					console.log('111111111',this.cartListData)
					for(let i in res.data.list.data){
						res.data.list.data[i].is_indeterminate = false
					}
					this.cartList = res.data.list
					this.checkSelect()
				} else {
					this.$message({message: res.msg,type: 'error',});
				}
				this.is_loading = false
			}})
		},
		bindDeleteProduct(tag,i1="",i2=""){//删除商品
			let dataList = JSON.parse(JSON.stringify(this.cartList.data))
			let cids = []
			let cid = ''
			let sid = ''
			if(tag=='all'){
				for(let i in dataList){
					if(dataList[i].is_select==1 || dataList[i].is_indeterminate){
						cids.push(dataList[i].business_cart_id)
					}
				}
				cid = cids.toString()
			}else if(tag=='item'){
				cid = dataList[i1].business_cart_id
			}else{
				cid = dataList[i1].business_cart_id
				sid = dataList[i1].sku[i2].product_sku_id
			}
			this.is_loading = true
			let param = {business_cart_id:cid,product_sku_id:sid}
			this.doDeleteOptions({data:param,success:(res)=>{
				if(res.code===200){
					// console.log(res)
					this.getList()
					this.$message({message: res.msg,type: 'success',});
				} else {
					this.$message({message: res.msg,type: 'error',});
				}
				this.is_loading = false
			}})
		},
		bindSelectOpt(bol,id1="",id2="",i1="",i2="",tag){//勾选商品
			// console.log(bol,id1,id2,i1,i2,tag)
			let dataList = JSON.parse(JSON.stringify(this.cartList.data))
			let param = {}
			if(tag=='all'){
				let ids = []
				for(let i in dataList){
					ids.push(dataList[i].business_cart_id)
				}
				ids = ids.toString()
				param = {business_cart_id:ids,product_sku_id:"",is_select:(bol?1:0)}
			}else{
				param = {business_cart_id:id1,product_sku_id:id2,is_select:(bol==1?0:1)}
				if(dataList[i1].product.del_date>0){
					this.$message({message: '该商品已失效!',type: 'error',});
					return false
				}
			}
			this.doSelectOptions({data:param,success:(res)=>{
				if(res.code===200){
					// console.log(res)
					if(tag=='all'){
						if(bol){
							for(let i in dataList){
								for(let d in dataList[i].sku){
									dataList[i].sku[d].pivot.is_select = 1
								}
								dataList[i].is_select = 1
							}
						}else{
							for(let i in dataList){
								for(let d in dataList[i].sku){
									dataList[i].sku[d].pivot.is_select = 0
								}
								dataList[i].is_select = 0
							}
						}
					}else if(tag=='sku'){
						dataList[i1].sku[i2].pivot.is_select = dataList[i1].sku[i2].pivot.is_select==1?0:1
					}else{
						dataList[i1].is_select = dataList[i1].is_select==1?0:1
						if(dataList[i1].is_select == 1){
							for(let i in dataList[i1].sku){
								dataList[i1].sku[i].pivot.is_select = 1
							}
						}else{
							for(let i in dataList[i1].sku){
								dataList[i1].sku[i].pivot.is_select = 0
							}
						}
					}
					this.cartList.data = dataList
					this.checkSelect()
					// this.$message({message: res.msg,type: 'success',});
				} else {
					this.$message({message: res.msg,type: 'error',});
				}
			}})
		},
		checkSelect(){//检测商品选中状态
			let dataList = JSON.parse(JSON.stringify(this.cartList.data))
			let listCount = 0
			for(let i in dataList){
				let skuCount = 0
				for(let d in dataList[i].sku){
					if(dataList[i].sku[d].pivot.is_select==1){
						skuCount += 1
					}
				}
				if(skuCount < dataList[i].sku.length && skuCount != 0){
					dataList[i].is_select = 0
					dataList[i].is_indeterminate = true
				}else if(skuCount == dataList[i].sku.length){
					dataList[i].is_select = 1
					dataList[i].is_indeterminate = false
				}else{
					dataList[i].is_select = 0
					dataList[i].is_indeterminate = false
				}
				if(dataList[i].is_select==1 || dataList[i].is_indeterminate){
					listCount += 1
				}
			}
			this.cartList.data = dataList
			if(listCount < dataList.length && listCount != 0){
				this.all_select = false
				this.all_indeterminate = true
			}else if(listCount == dataList.length){
				this.all_select = true
				this.all_indeterminate = false
			}else{
				this.all_select = false
				this.all_indeterminate = false
			}
			this.checkNums()
		},
		checkNums(){//检测选中商品及类目数量
			let dataList = JSON.parse(JSON.stringify(this.cartList.data))
			if(dataList.length==0){
				this.select_types = 0
				this.select_nums = 0
				this.all_select = false
				this.all_indeterminate = false
				return false
			}
			let selectType = 0
			let selectCount = 0
			for(let i in dataList){
				for(let d in dataList[i].sku){
					if(dataList[i].sku[d].pivot.is_select==1){
						selectCount += 1
					}
				}
				if(dataList[i].is_select==1 || dataList[i].is_indeterminate){
					selectType += 1
				}
				this.select_types = selectType
				this.select_nums = selectCount
			}
		},
		bindMoreSkus(id){//获取"再选一款"产品类目列表
			this.getMoreSkus({data:{business_cart_id:id},success:(res)=>{
				if(res.code===200){
					console.log(JSON.parse(JSON.stringify(res)))
					this.is_other = true
					this.otherData = res.msg
					let arr = JSON.parse(JSON.stringify(res.msg.sku))
					for(let i in arr){
						arr[i].is_select = 0
					}
					this.otherList = arr
					this.more_business_cart_id = id
				} else {
					this.$message({message: res.msg,type: 'error',});
				}
			}})
		},
		bindMoreSelect(tag,index){//"再选一款"产品选择框勾选
			let list = JSON.parse(JSON.stringify(this.otherList))
			if(tag=='all'){
				if(this.other_select){
					for(let i in list){ list[i].is_select = 1 }
				}else{
					for(let i in list){ list[i].is_select = 0 }
				}
				this.other_indeterminate = false
			}else{
				list[index].is_select = list[index].is_select==1?0:1
				let count = 0
				for(let i in list){
					if(list[i].is_select==1){
						count += 1
					}
					if(count < list.length && count != 0){
						this.other_select = false
						this.other_indeterminate = true
					}else if(count == list.length){
						this.other_select = true
						this.other_indeterminate = false
					}else{
						this.other_select = false
						this.other_indeterminate = false
					}
				}
			}
			this.otherList = list
		},
		bindOtherClose(tag){//"再选一款"产品确定及弹窗关闭
			let list = JSON.parse(JSON.stringify(this.otherList))
			if(tag=='close'){
				this.is_other = false
				this.other_select = false
				this.other_indeterminate = false
			}else{
				if(!this.other_indeterminate && !this.other_select){
					this.$message({message: '请勾选产品类目！',type: 'error',});
					return false
				}
				let ids = []
				let id = ""
				for(let i in list){ 
					if(list[i].is_select == 1){
						ids.push(list[i].product_sku_id)
					}
				}
				id = ids.toString()
				let param = {product_id:this.otherData.product_id,product_sku_id:id}
				this.doProductSelect({data:param,success:(res)=>{
					if(res.code===200){
						// console.log(res)
						this.$message({message: res.msg,type: 'success',});
						this.is_other = false
						this.other_select = false
						this.other_indeterminate = false
						this.getList()
					} else {
						this.$message({message: res.msg,type: 'error',});
					}
					this.is_loading = false
				}})
			}
		},
		callPh(){//打开子组件店铺列表
			if(!this.all_indeterminate && !this.all_select){
				this.$message({message: '未勾选商品！',type: 'error',});
				return false
			}
			this.$refs.child.showShopList()
		},
		phSubmit(val,strategy){//立即铺货
			let param = {shop_id:val,type:'2',strategy: strategy}
			this.doSubmitPH({data:param,success:(res)=>{
				if(res.code===200){
					this.$message({message: res.msg,type: 'success',});
					setTimeout(()=>{
						this.$refs.child.closeShopList()
						this.getList()
					},300)
				} else {
					this.$message({message: res.msg,type: 'error',});
				}
			}})
		}
		
	}
}
</script>
<style scoped>
.cart_main{padding: 40px 0;width: 100%;}
.cart_main .title_txt{font-size: 24px;line-height: 1;}
.list_title{width: 100%;height: 50px;background: #fff;margin-top: 20px;display: flex;align-items: center;line-height: 1;}
.list_title .checkall{margin-left: 20px;}
.list_title .t1{width: 560px;margin-left: 68px;}
.list_title .t2{flex: 1;}
.cart_list>li{margin-top: 16px;background: #fff;padding: 20px 0;display: flex;align-items: flex-start;position: relative;}
.cart_list>li .invalid_box{width: 100%;height: 100%;position: absolute;top: 0;left: 0;z-index: 11;background-color: rgba(255, 255, 255, .5);display: flex;justify-content: center;align-items: center;font-size: 24px;color: #6b6b6b;cursor: no-drop;}
.cart_list>li .check_box{margin: 26px 20px 0;}
.cart_list>li .goods_wrap{width: 0;flex: 1;display: flex;align-items: flex-start;}
.goods_wrap .img_box{width: 70px;height: 70px;background: #eee;border-radius: 6px;overflow: hidden;}
.goods_wrap .con_box{flex: 1;width: 0;margin-left: 20px;display: flex;flex-direction: column;align-items: flex-start;}
.goods_wrap .con_box .name{line-height: 1;font-size: 14px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;}
.goods_wrap .con_box .config_list{margin-top: 20px;max-height: 190px;overflow: hidden auto;float: left;}
.goods_wrap .con_box .more_btn{width: 74px;height: 28px;background-color: #ffffff;border-radius: 5em;border: solid 1px #dddddd;display: flex;justify-content: center;align-items: center;box-sizing: border-box;font-size: 12px;color: #666666;cursor: pointer;margin-top: 20px;}
.goods_wrap .con_box .more_btn:hover{border-color: var(--red);color: var(--red);}
.config_list>li{margin-bottom: 10px;display: flex;align-items: center;}
.config_list>li:last-child{margin-bottom: 0;}
.config_list>li .icon_box{width: 30px;height: 30px;background: #eee;border-radius: 4px;overflow: hidden;margin-left: 14px;}
.config_list>li .config{width: 300px;height: 30px;background: #f5f5f5;border-radius: 4px;box-sizing: border-box;margin-left: 14px;padding: 0 10px;font-size: 12px;color: #666;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;line-height: 30px;}
.config_list>li .icon-guanbi{margin-left: 14px;font-size: 12px;color: #999;cursor: pointer;}
.config_list>li .icon-guanbi:hover{color: #f0413e;}
.config_list>li .price{width: 88px;margin-left: 160px;text-align: center;font-size: 12px;}
.cart_list>li .opts_wrap{line-height: 1;position: absolute;top: 24px;right: 20px;z-index: 22;display: flex;align-items: center;color: #cccccc;}
.cart_list>li .opts_wrap .opt{display: flex;align-items: center;font-size: 14px;color: #558ed5;cursor: pointer;margin-left: 8px;padding-left: 8px;border-left: 1px solid #cccccc;}
.cart_list>li .opts_wrap .opt:first-child{border-left: none;}
.cart_list>li .opts_wrap .opt:hover{color: var(--red);text-decoration: underline;}
.cart_list>li .opts_wrap .icon-wenhao{font-size: 15px;color: #999;margin-left: 6px;cursor: pointer;}
.cart_list>li .delete{width: 36px;height: 36px;background-color: #eeeeee;border-radius: 6px 0px 0px 0px;position: absolute;bottom: 0;right: 0;z-index: 22;cursor: pointer;display: flex;justify-content: center;align-items: center;}
.cart_list>li .delete .icon-delete{font-size: 16px;color: #666666;}
.cart_list>li .delete:hover .icon-delete{color: var(--red);}
.baoyou_mask{width: 100%;height: 100%;position: fixed;top: 0;left: 0;background: rgba(0, 0, 0, .5);z-index: 999;transition: all .5s;visibility: hidden;opacity: 0;}
.baoyou_mask.active{visibility: visible;opacity: 1;}
.baoyou_mask .baoyou_main{width: 400px;height: auto;box-sizing: border-box;padding: 36px 0 40px;background: #fff;position: absolute;top: 50%;left: 50%;transform: translate(-50%,-70%);visibility: hidden;opacity: 0;transition: all .5s;}
.baoyou_mask.active .baoyou_main{transform: translate(-50%,-50%);visibility: visible;opacity: 1;}
.baoyou_main .icon-guanbi{font-size: 12px;color: #999;position: absolute;top: 16px;right: 16px;cursor: pointer;z-index: 222;}
.baoyou_main .title{margin: 0 22px;font-size: 20px;line-height: 1;font-weight: bold;}
.baoyou_main .tx_count{margin: 24px 32px 6px;height: 80px;background: #f5f5f5;border-radius: 4px;box-sizing: border-box;padding: 0 18px;display: flex;align-items: center;}
.baoyou_main .tx_count .in{font-size: 30px;color: #333;font-weight: bold;flex: 1;width: 0;}
.baoyou_main .tx_count .in::placeholder{font-size: 16px;color: #cccccc;line-height: 34px;}
.baoyou_main .btn_wrap{margin: 36px 32px 0;display: flex;justify-content: space-between;}
.baoyou_main .btn_wrap .btn{width: 160px;height: 40px;box-sizing: border-box;border-radius: 6px;display: flex;justify-content: center;align-items: center;font-size: 14px;cursor: pointer;}
.baoyou_main .btn_wrap .btn:nth-child(1){border: 1px solid #dddddd;color: #333;}
.baoyou_main .btn_wrap .btn:nth-child(2){background: #f0413e;color: #fff;}
.bottom_total{width: 100%;height: 80px;background: #fff;margin-top: 20px;display: flex;align-items: center;justify-content: space-between;line-height: 1;}
.bottom_total .left{display: flex;align-items: center;margin-left: 20px;}
.bottom_total .left .delete{width: 60px;height: 28px;background-color: #ffffff;border-radius: 5em;border: solid 1px #dddddd;display: flex;justify-content: center;align-items: center;box-sizing: border-box;font-size: 12px;color: #666666;cursor: pointer;margin-left: 30px;}
.bottom_total .right{display: flex;align-items: center;margin-right: 20px;}
.bottom_total .right .total_txt{color: #666666;margin-right: 20px;}
.bottom_total .right .submit_btn{margin-left: 10px;width: 135px;height: 40px;background-color: #f0413e;border-radius: 5em;display: flex;justify-content: center;align-items: center;color: #fefefe;cursor: pointer;}
.bottom_total .left .delete:hover{border-color: var(--red);color: var(--red);}

.config_list::-webkit-scrollbar{width:4px;}
.config_list::-webkit-scrollbar-thumb{background: #DDDDDD;border-radius: 4px;}
.config_list::-webkit-scrollbar-track{background: #F2F2F2;border-radius: 4px;}

.other_mask{width: 100%;height: 100%;position: fixed;top: 0;left: 0;background: rgba(0, 0, 0, .5);z-index: 999;transition: all .5s;visibility: hidden;opacity: 0;}
.other_mask.active{visibility: visible;opacity: 1;}
.other_mask .other_main{width: auto;height: auto;box-sizing: border-box;padding: 40px 30px;background: #fff;position: absolute;top: 50%;left: 50%;transform: translate(-50%,-70%);visibility: hidden;opacity: 0;transition: all .5s;}
.other_mask.active .other_main{transform: translate(-50%,-50%);visibility: visible;opacity: 1;}
.other_main .main{width: 600px;}
.other_main .icon-guanbi{font-size: 12px;color: #999;position: absolute;top: 16px;right: 16px;cursor: pointer;z-index: 222;}
.other_main .title{font-size: 20px;line-height: 1;font-weight: bold;margin: 0 10px;}
.other_main .config_list{margin: 30px 10px 0;max-height: 190px;overflow: hidden auto;}
.other_main .config_list>li .config{flex: 1;}
.other_main .config_list>li .price{margin-left: 20px;}
.other_main .bottom_total{margin-top: 30px;width: 100%;height: 50px;background-color: #fff;box-sizing: border-box;display: flex;justify-content: space-between;align-items: center;box-shadow: 0 0 5px 0 #eee;padding: 0 10px;}
.other_main .bottom_total .btn{width: 100px;height: 32px;box-sizing: border-box;border-radius: 4px;display: flex;justify-content: center;align-items: center;font-size: 14px;cursor: pointer;background: #f0413e;color: #fff;}
.other_main .nodata{width: 250px;}
.other_main .nodata .txt{font-size: 14px;color: #666;text-align: center;line-height: 20px;}
.other_main .nodata .btn{width: 120px;height: 36px;box-sizing: border-box;border-radius: 4px;display: flex;justify-content: center;align-items: center;font-size: 14px;cursor: pointer;border: 1px solid #dddddd;color: #333;margin: 40px auto 0;}

	
</style>
<style>
.el-empty{background-color: #fff;margin-top: 20px;}
.el-checkbox{color: #666;display: flex;align-items: center;}
.el-checkbox__inner{border-color: #ddd;width: 18px;height: 18px;}
.el-checkbox__input{display: flex;}
.el-checkbox__label{font-size: 16px;line-height: 1;}
.el-checkbox__inner::after{height: 10px;left: 6px;}
.el-checkbox__input.is-indeterminate .el-checkbox__inner::before{height: 4px;}

/*red border color*/
.el-button.is-plain:focus, .el-button.is-plain:hover ,.el-checkbox__inner:hover ,.el-checkbox__input.is-focus .el-checkbox__inner,.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner, .el-pagination__jump .el-input__inner:focus{border-color: var(--red);}

/*red color*/
.el-button.is-plain:focus, .el-button.is-plain:hover, .el-checkbox__input.is-checked+.el-checkbox__label, .el-pager li.active ,.el-pagination button:hover ,.el-pager li:hover{color: var(--red);}

/*red background color*/
.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{background-color: var(--red);}
</style>